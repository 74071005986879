/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useState } from "react"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import FloatingInput from "../../../components/Elements/Form/FloatingInput"
import FloatingCheckbox from "../../../components/Elements/Form/FloatingCheckbox"
import MyUserSegmentationContext from "../../../context/MyUserSegmentation/MyUserSegmentationContext"
import {
  Table,
  Tr,
  Td,
} from "../../../components/Table/TableElements"
import Skeleton from "../../../components/Skeleton"
import CAProvinces from "../../../utils/canadian-provinces.json"

const Companies = props => {

  const {
    segments,
    data,
    getCompanies,
    create,
    editData,
    setEditData,
    editing,
    segment,
    filters,
    setFilters,
  } = useContext(MyUserSegmentationContext)
  const [showSelectedOnly, setShowSelectedOnly] = useState(false)

  useEffect(() => {
    getCompanies()
  }, [])

  const initColumns = [
    'Company name',
    'Regions',
    'Countries',
    'Stores',
    'Users',
  ]

  const [columns, setColumns] = useState(initColumns)

  useEffect(() => {
    if (segment !== null) {
      setColumns([
        <FloatingCheckbox
          flush
          name="select_all"
          value={null}
          hookForm={false}
          disabled={!editing}
          checked={
            segments.filter(i => parseInt(i.id) === parseInt(segment.value))[0].field_group_companies.length === data.companies.length
          }
          onChange={e => {
            if (e.target.checked) {
              setEditData({
                ...editData,
                field_group_companies: data.companies.map(i => Object.assign({ target_id: parseInt(i.id) }))
              })
            } else {
              setEditData({
                ...editData,
                field_group_companies: []
              })
            }
          }}
        />,
        ...initColumns
      ])
    } else if (create === true) {
      setColumns([
        <FloatingCheckbox
          flush
          name="select_all"
          value={null}
          hookForm={false}
          disabled={!editing}
          checked={
            'field_group_companies' in editData ? editData?.field_group_companies?.length === data.companies.length : false
          }
          onChange={e => {
            if (e.target.checked) {
              setEditData({
                ...editData,
                field_group_companies: data.companies.map(i => Object.assign({ target_id: parseInt(i.id) }))
              })
            } else {
              setEditData({
                ...editData,
                field_group_companies: []
              })
            }
          }}
        />,
        ...initColumns
      ])
    } else {
      setColumns(initColumns)
    }
  }, [segment, editing])

  return (
    <>
      <div className="border border-[#EBEBEB] rounded px-4 py-4 mb-8">
        <div className="flex max-w-[1400px] space-x-4 items-center"
          css={
            css`
              &&& {
                > * {
                  margin-bottom:0!important;
                }
              }
            `
          }>
          <FloatingInput
            label="Search for anything..."
            name="Search"
            flush
            value={filters.companies.search || ''}
            onChange={(e) => {
              setFilters((prev) => {
                return {
                  ...prev,
                  companies: {
                    ...prev.companies,
                    search: e.target.value
                  }
                }
              })
            }}
          />
          <FloatingSelect
            label="Filter by country"
            options={[
              { value: "CA", label: "Canada" },
              { value: "US", label: "United States of America" },
            ]}
            value={filters.companies.country || null}
            onChange={(e) => {
              setFilters((prev) => {
                return {
                  ...prev,
                  companies: {
                    ...prev.companies,
                    country: e
                  }
                }
              })
            }}
            name="Search"
            flush
          />
          <FloatingSelect
            label="Filter by region"
            // make an array of all the unique regions in the data.stores array
            options={
              data.companies
                .map(i => i.regions.split(','))
                .flat()
                .filter((v, i, a) => a.indexOf(v) === i)
                .map(i => {
                  return {
                    value: i,
                    label: i
                  }
                }
                )

            }

            value={filters.companies.regions || null}
            onChange={(e) => {
              setFilters((prev) => {
                return {
                  ...prev,
                  companies: {
                    ...prev.companies,
                    regions: e
                  }
                }
              })
            }}
            isMulti={true}
            name="Search"
            flush
          />
          {segment !== null &&
            <div className="grow w-full">
              <FloatingCheckbox
                label="Show only selected rows"
                name="selected"
                onChange={e => setShowSelectedOnly(e.target.checked)}
                value={showSelectedOnly}
                flush
                hookForm={false}
              />
            </div>
          }
        </div>
      </div>

      {data.companies.length === 0 ?
        <Skeleton table /> :
        <Table
          columns={columns}
        >
          {data.companies
            .filter(item => {
              if (showSelectedOnly) {
                if (segment !== null) {
                  return segments.filter(i => parseInt(i.id) === parseInt(segment.value))[0].field_group_companies.filter(i => parseInt(i.target_id) === parseInt(item.id)).length > 0
                } else if (create === true) {
                  return 'field_group_companies' in editData ? editData?.field_group_companies?.filter(i => parseInt(i.target_id) === parseInt(item.id)).length > 0 : false
                }
              }
              return true
            })
            .filter(item => {
              if (filters.companies.search !== '') {
                return item.title.toLowerCase().includes(filters.companies.search.toLowerCase())
              }
              return true
            })
            .filter(item => {
              if (filters.companies.country !== null) {
                return item.countries.split(',').filter(i => i === filters.companies.country.value).length > 0
              }
              return true
            })
            .filter(item => {
              if (filters.companies.regions !== null && filters.companies.regions.length > 0) {
                return item.regions.split(',').filter(i => filters.companies.regions.map(i => i.value).includes(i)).length > 0
              }
              return true
            })
            // .sort((a, b) => {
            //   if (sort.column === 'title') {
            //     if (sort.direction === 'asc') {
            //       return a.title.localeCompare(b.title)
            //     } else {
            //       return b.title.localeCompare(a.title)
            //     }
            //   }
            //   return 0
            // })

            .map((item, k) => {
              let regions = item.regions
              if (item.regions.length && item.regions !== null) regions = item.regions.split(",").map(i => CAProvinces.find(f => f.short === i)?.name || "").join(", ")

              return (
                <Tr key={k}>
                  {((segment !== null && editData !== null && Object.keys(editData).length > 0) || create === true) &&
                    <Td className="w-[40px] pr-0">
                      <FloatingCheckbox
                        name="selected"
                        value={'field_group_companies' in editData && editData.field_group_companies.filter(i => parseInt(i.target_id) === parseInt(item.id)).length > 0 ? true : false}
                        hookForm={false}
                        disabled={!editing}
                        flush
                        onChange={(e) => {
                          if (e.target.checked) {
                            const existingCompanies = 'field_group_companies' in editData ? editData.field_group_companies : []
                            setEditData({
                              ...editData,
                              field_group_companies: [
                                ...existingCompanies,
                                {
                                  target_id: parseInt(item.id),
                                }
                              ]
                            })
                          }
                          if (!e.target.checked) {
                            setEditData({
                              ...editData,
                              field_group_companies: editData.field_group_companies.filter(i => parseInt(i.target_id) !== parseInt(item.id))
                            })
                          }
                        }}
                      />
                    </Td>}
                  <Td>{item.title}</Td>
                  <Td>{regions}</Td>
                  <Td>{item.countries.split(",").map(i => i === "CA" ? "Canada" : "USA")}</Td>
                  <Td>{item.stores}</Td>
                  <Td>{item.users}</Td>
                </Tr>
              )
            })}
        </Table>}
    </>
  )
}
export default Companies