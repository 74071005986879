/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useState } from "react"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import FloatingInput from "../../../components/Elements/Form/FloatingInput"
import FloatingCheckbox from "../../../components/Elements/Form/FloatingCheckbox"
import MyUserSegmentationContext from "../../../context/MyUserSegmentation/MyUserSegmentationContext"
import {
  Table,
  Tr,
  Td,
} from "../../../components/Table/TableElements"
import Skeleton from "../../../components/Skeleton"
import CAProvinces from "../../../utils/canadian-provinces.json"

const Stores = props => {

  const {
    segments,
    data,
    getStores,
    create,
    editData,
    setEditData,
    editing,
    segment,
    filters,
    setFilters,
  } = useContext(MyUserSegmentationContext)
  const [showSelectedOnly, setShowSelectedOnly] = useState(false)

  const initColumns = [
    'Store name',
    'Company',
    'Region',
    'City',
    'Street Address',
    'Unit',
    'Postal Code',
    'Users',
  ]

  useEffect(() => {
    getStores()
  }, [])

  const [columns, setColumns] = useState(initColumns)

  useEffect(() => {
    if (segment !== null) {
      setColumns([
        <FloatingCheckbox
          flush
          name="select_all"
          value={null}
          hookForm={false}
          disabled={!editing}
          checked={
            segments.filter(i => parseInt(i.id) === parseInt(segment.value))[0].field_group_stores.length === data.stores.length
          }
          onChange={e => {
            if (e.target.checked) {
              setEditData({
                ...editData,
                field_group_stores: data.stores.map(i => Object.assign({ target_id: parseInt(i.id) }))
              })
            } else {
              setEditData({
                ...editData,
                field_group_stores: []
              })
            }
          }}
        />,
        ...initColumns
      ])
    } else if (create === true) {
      setColumns([
        <FloatingCheckbox
          flush
          name="select_all"
          value={null}
          hookForm={false}
          disabled={!editing}
          checked={
            'field_group_stores' in editData ? editData?.field_group_stores?.length === data.stores.length : false
          }
          onChange={e => {
            if (e.target.checked) {
              setEditData({
                ...editData,
                field_group_stores: data.stores.map(i => Object.assign({ target_id: parseInt(i.id) }))
              })
            } else {
              setEditData({
                ...editData,
                field_group_stores: []
              })
            }
          }}
        />,
        ...initColumns
      ])
    } else {
      setColumns(initColumns)
    }
  }, [segment, editing])

  return (
    <>
      <div className="border border-[#EBEBEB] rounded px-4 py-4 mb-8">
        <div className="flex max-w-[1400px] space-x-4 items-center"
          css={
            css`
              &&& {
                > * {
                  margin-bottom:0!important;
                }
              }
            `
          }>
          <FloatingInput
            label="Search for anything..."
            name="Search"
            flush
            value={filters.stores.search || ''}
            onChange={(e) => {
              setFilters((prev) => {
                return {
                  ...prev,
                  stores: {
                    ...prev.stores,
                    search: e.target.value
                  }
                }
              })
            }}
          />
          <FloatingSelect
            label="Filter by company"

            // make an array of all the unique companies in the data.stores array
            options={
              data.stores
                .map(i => i.company)
                .filter((v, i, a) => a.indexOf(v) === i)
                .map(i => {
                  return {
                    value: i,
                    label: i
                  }
                }
                )
            }
            value={filters.stores.companies || null}
            onChange={(e) => {
              setFilters((prev) => {
                return {
                  ...prev,
                  stores: {
                    ...prev.stores,
                    companies: e
                  }
                }
              })
            }}
            isMulti={true}
            name="Search"
            flush
          />
          <FloatingSelect
            label="Filter by region"
            // make an array of all the unique regions in the data.stores array
            options={
              data.stores

                .map(i => i.region)
                .filter((v, i, a) => a.indexOf(v) === i)
                .map(i => {
                  return {
                    value: i,
                    label: i
                  }
                }
                )
            }
            value={filters.stores.regions || null}
            onChange={(e) => {
              setFilters((prev) => {
                return {
                  ...prev,
                  stores: {
                    ...prev.stores,
                    regions: e
                  }
                }
              })
            }}
            isMulti={true}
            name="Search"
            flush
          />
          <FloatingSelect
            label="Filter by city"
            // make an array of all the unique cities in the data.stores array
            options={
              data.stores

                .map(i => i.city)
                .filter((v, i, a) => a.indexOf(v) === i)
                .map(i => {
                  return {
                    value: i,
                    label: i
                  }
                }
                )
            }

            value={filters.stores.cities || null}
            onChange={(e) => {
              setFilters((prev) => {
                return {
                  ...prev,
                  stores: {
                    ...prev.stores,
                    cities: e
                  }
                }
              })
            }}
            isMulti={true}
            name="Search"
            flush
          />
          {segment !== null &&
            <div className="grow w-full">
              <FloatingCheckbox
                label="Show only selected rows"
                name="selected"
                onChange={e => setShowSelectedOnly(e.target.checked)}
                value={showSelectedOnly}
                flush
                hookForm={false}
              />
            </div>
          }
        </div>
      </div>

      {data.stores.length === 0 ?
        <Skeleton table /> :
        <Table
          fit
          columns={columns}
        >
          {data.stores
            .filter(i => {
              if (showSelectedOnly) {
                if (segment !== null) {
                  return segments.filter(i => parseInt(i.id) === parseInt(segment.value))[0].field_group_stores.filter(j => parseInt(j.target_id) === parseInt(i.id)).length > 0
                } else if (create === true) {
                  return 'field_group_stores' in editData ? editData?.field_group_stores?.filter(j => parseInt(j.target_id) === parseInt(i.id)).length > 0 : false
                }
              } else {
                return true
              }
            })
            .filter(i => {
              if (filters.stores.search !== '') {
                return i.title.toLowerCase().includes(filters.stores.search.toLowerCase())
              } else {
                return true
              }
            })
            .filter(item => {
              if (filters.stores.companies !== null && filters.stores.companies.length > 0) {
                return filters.stores.companies.map(i => i.label).includes(item.company)
              } else {
                return true
              }
            })
            .filter(item => {
              if (filters.stores.regions !== null && filters.stores.regions.length > 0) {
                return filters.stores.regions.map(i => i.value).includes(item.region)
              } else {
                return true
              }
            })
            .filter(item => {
              if (filters.stores.cities !== null && filters.stores.cities.length > 0) {
                return filters.stores.cities.map(i => i.value).includes(item.city)
              } else {
                return true
              }
            })

            .map((item, k) => {
              return (
                <Tr key={k}>
                  {((segment !== null && editData !== null && Object.keys(editData).length > 0) || create === true) &&
                    <Td className="w-[40px] pr-0">
                      <FloatingCheckbox
                        name="selected"
                        value={'field_group_stores' in editData && editData.field_group_stores.filter(i => parseInt(i.target_id) === parseInt(item.id)).length > 0 ? true : false}
                        hookForm={false}
                        disabled={!editing}
                        flush
                        onChange={(e) => {
                          if (e.target.checked) {
                            const existingStores = 'field_group_stores' in editData ? editData.field_group_stores : []
                            setEditData({
                              ...editData,
                              field_group_stores: [
                                ...existingStores,
                                {
                                  target_id: parseInt(item.id),
                                }
                              ]
                            })
                          }
                          if (!e.target.checked) {
                            setEditData({
                              ...editData,
                              field_group_stores: editData.field_group_stores.filter(i => parseInt(i.target_id) !== parseInt(item.id))
                            })
                          }
                        }}
                      />
                    </Td>}
                  <Td className="!whitespace-normal">{item.title}</Td>
                  <Td>{item.company}</Td>
                  <Td>{item.region !== "" && item.region !== null ? CAProvinces.find(f => f.short === item.region)?.name || "" : ""}</Td>
                  <Td>{item.city}</Td>
                  <Td className="!whitespace-normal">{item.address1}</Td>
                  <Td>{item.address2}</Td>
                  <Td>{item.postal_code}</Td>
                  <Td>{item.users}</Td>
                </Tr>
              )
            })}
        </Table>}
    </>
  )
}
export default Stores