/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useState } from "react"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import FloatingInput from "../../../components/Elements/Form/FloatingInput"
import FloatingCheckbox from "../../../components/Elements/Form/FloatingCheckbox"
import MyUserSegmentationContext from "../../../context/MyUserSegmentation/MyUserSegmentationContext"
import { Table, Tr, Td } from "../../../components/Table/TableElements"
import Skeleton from "../../../components/Skeleton"

const Regions = props => {
  const {
    segments,
    data,
    getRegions,
    create,
    editData,
    setEditData,
    editing,
    segment,
    filters,
    setFilters,
  } = useContext(MyUserSegmentationContext)
  console.log(create)
  const [showSelectedOnly, setShowSelectedOnly] = useState(false)
  // useEffect(() => {
  //   if (segment !== null) {
  //     setEditData(segments.filter(i => parseInt(i.id) === parseInt(segment.value))[0])
  //   }
  // }, [segment])

  useEffect(() => {
    getRegions()
  }, [])

  const initColumns = ["Region name", "Country", "Companies", "Stores", "Users"]

  const [columns, setColumns] = useState(initColumns)

  useEffect(() => {
    if (segment !== null) {
      console.log(segment, "segment")
      console.log(segments, "segmentssss")
      setColumns([
        <FloatingCheckbox
          flush
          name="select_all"
          value={null}
          hookForm={false}
          disabled={!editing}
          checked={
            segments.filter(i => parseInt(i.id) === parseInt(segment.value))[0]
              .field_group_regions.length === data.regions.length
          }
          onChange={e => {
            if (e.target.checked) {
              setEditData({
                ...editData,
                field_group_regions: data.regions.map(i =>
                  Object.assign({ target_id: parseInt(i.id) })
                ),
              })
            } else {
              setEditData({
                ...editData,
                field_group_regions: [],
              })
            }
          }}
        />,
        ...initColumns,
      ])
    } else if (create === true) {
      setColumns([
        <FloatingCheckbox
          flush
          name="select_all"
          value={null}
          hookForm={false}
          disabled={!editing}
          checked={
            "field_group_regions" in editData
              ? editData?.field_group_regions?.length === data.regions.length
              : false
          }
          onChange={e => {
            if (e.target.checked) {
              setEditData({
                ...editData,
                field_group_regions: data.regions.map(i =>
                  Object.assign({ target_id: parseInt(i.id) })
                ),
              })
            } else {
              setEditData({
                ...editData,
                field_group_regions: [],
              })
            }
          }}
        />,
        ...initColumns,
      ])
    } else {
      setColumns(initColumns)
    }
  }, [segment, segments, editing, create])

  return (
    <>
      <div className="border border-[#EBEBEB] rounded px-4 py-4 mb-8">
        <div
          className="flex max-w-[1400px] space-x-4 items-center"
          css={css`
            &&& {
              > * {
                margin-bottom: 0 !important;
              }
            }
          `}
        >
          <FloatingInput
            label="Search for anything..."
            name="Search"
            flush
            value={filters.regions.search || ""}
            onChange={e => {
              setFilters(prev => {
                return {
                  ...prev,
                  regions: {
                    ...prev.regions,
                    search: e.target.value,
                  },
                }
              })
            }}
          />
          <FloatingSelect
            label="Filter by Country"
            options={[
              { value: "CA", label: "Canada" },
              { value: "US", label: "United States of America" },
            ]}
            value={filters.regions.country || null}
            onChange={e => {
              setFilters(prev => {
                return {
                  ...prev,
                  regions: {
                    ...prev.regions,
                    country: e,
                  },
                }
              })
            }}
            name="Search"
            flush
          />
          {segment !== null && (
            <div className="grow w-full">
              <FloatingCheckbox
                label="Show only selected rows"
                name="selected"
                onChange={e => setShowSelectedOnly(e.target.checked)}
                value={showSelectedOnly}
                flush
                hookForm={false}
              />
            </div>
          )}
        </div>
      </div>
      {data.regions.length === 0 ? (
        <Skeleton table />
      ) : (
        <Table columns={columns}>
          {data.regions
            .filter(item => {
              if (showSelectedOnly) {
                return (
                  editData.field_group_regions.filter(
                    i => parseInt(i.target_id) === parseInt(item.id)
                  ).length > 0
                )
              } else {
                return true
              }
            })
            .filter(item => {
              if (filters.regions.search !== "") {
                return item.title
                  .toLowerCase()
                  .includes(filters.regions.search.toLowerCase())
              } else {
                return true
              }
            })
            .filter(item => {
              if (filters.regions.country !== null) {
                return item.country === filters.regions.country.value
              } else {
                return true
              }
            })
            .map((item, k) => {
              return (
                <Tr key={k}>
                  {((segment !== null &&
                    editData !== null &&
                    Object.keys(editData).length > 0) ||
                    create === true) && (
                    <Td className="w-[40px] pr-0">
                      <FloatingCheckbox
                        name="selected"
                        value={
                          "field_group_regions" in editData &&
                          editData.field_group_regions.filter(
                            i => parseInt(i.target_id) === parseInt(item.id)
                          ).length > 0
                            ? true
                            : false
                        }
                        hookForm={false}
                        disabled={!editing}
                        flush
                        onChange={e => {
                          if (e.target.checked) {
                            const existingRegions =
                              "field_group_regions" in editData
                                ? editData.field_group_regions
                                : []
                            setEditData({
                              ...editData,
                              field_group_regions: [
                                ...existingRegions,
                                {
                                  target_id: parseInt(item.id),
                                },
                              ],
                            })
                          }
                          if (!e.target.checked) {
                            setEditData({
                              ...editData,
                              field_group_regions:
                                editData.field_group_regions.filter(
                                  i =>
                                    parseInt(i.target_id) !== parseInt(item.id)
                                ),
                            })
                          }
                        }}
                      />
                    </Td>
                  )}
                  <Td>{item.title}</Td>
                  <Td>{item.country === "CA" ? "Canada" : "USA"}</Td>
                  <Td>{item.companies}</Td>
                  <Td>{item.stores}</Td>
                  <Td>{item.users}</Td>
                </Tr>
              )
            })}
        </Table>
      )}
    </>
  )
}
export default Regions
