/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useState } from "react"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import FloatingInput from "../../../components/Elements/Form/FloatingInput"
import FloatingCheckbox from "../../../components/Elements/Form/FloatingCheckbox"
import MyUserSegmentationContext from "../../../context/MyUserSegmentation/MyUserSegmentationContext"
import { Table, Tr, Td } from "../../../components/Table/TableElements"
import Skeleton from "../../../components/Skeleton"
import CAProvinces from "../../../utils/canadian-provinces.json"

const Users = props => {
  const {
    segments,
    data,
    getUsers,
    create,
    editData,
    setEditData,
    editing,
    segment,
    filters,
    setFilters,
  } = useContext(MyUserSegmentationContext)
  const [showSelectedOnly, setShowSelectedOnly] = useState(false)

  const initColumns = [
    "User First Name",
    "User Last Name",
    "Job Title",
    "Regions",
    "User Type",
  ]

  useEffect(() => {
    getUsers()
  }, [])

  const [columns, setColumns] = useState(initColumns)

  useEffect(() => {
    if (segment !== null) {
      setColumns([
        <FloatingCheckbox
          flush
          name="select_all"
          value={null}
          hookForm={false}
          disabled={!editing}
          checked={
            segments.filter(i => parseInt(i.id) === parseInt(segment.value))[0]
              .field_group_users.length === data.users.length
          }
          onChange={e => {
            if (e.target.checked) {
              setEditData({
                ...editData,
                field_group_users: data.users.map(i =>
                  Object.assign({ target_id: parseInt(i.id) })
                ),
              })
            } else {
              setEditData({
                ...editData,
                field_group_users: [],
              })
            }
          }}
        />,
        ...initColumns,
      ])
    } else if (create === true) {
      setColumns([
        <FloatingCheckbox
          flush
          name="select_all"
          value={null}
          hookForm={false}
          disabled={!editing}
          checked={
            "field_group_users" in editData
              ? editData?.field_group_users?.length === data.users.length
              : false
          }
          onChange={e => {
            if (e.target.checked) {
              setEditData({
                ...editData,
                field_group_users: data.users.map(i =>
                  Object.assign({ target_id: parseInt(i.id) })
                ),
              })
            } else {
              setEditData({
                ...editData,
                field_group_users: [],
              })
            }
          }}
        />,
        ...initColumns,
      ])
    } else {
      setColumns(initColumns)
    }
  }, [segment, editing])

  console.log(
    data.users
      .map(i =>
        i.regions && i.regions?.includes(",") ? i.regions.split(",") : i.regions
      )
      .filter(f => f !== "" && f !== null)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map(i => {
        return {
          value: i,
          label: i,
        }
      }),
    "x"
  )

  return (
    <>
      <div className="border border-[#EBEBEB] rounded px-4 py-4 mb-8">
        <div
          className="flex max-w-[1400px] space-x-4 items-center"
          css={css`
            &&& {
              > * {
                margin-bottom: 0 !important;
              }
            }
          `}
        >
          <FloatingInput
            label="Search for anything..."
            name="Search"
            flush
            value={filters.users.search || ""}
            onChange={e => {
              setFilters(prev => {
                return {
                  ...prev,
                  users: {
                    ...prev.users,
                    search: e.target.value,
                  },
                }
              })
            }}
          />
          <FloatingSelect
            label="Filter by Job Title"
            options={data.users
              .map(i =>
                i.job_title && i.job_title?.includes(",")
                  ? i.job_title.split(",")
                  : i.job_title
              )
              .filter(f => f !== "" && f !== null)
              .filter((v, i, a) => a.indexOf(v) === i)
              .map(i => {
                return {
                  value: i,
                  label: i,
                }
              })}
            value={filters.users.job_titles || null}
            onChange={e => {
              setFilters(prev => {
                return {
                  ...prev,
                  users: {
                    ...prev.users,
                    job_titles: e,
                  },
                }
              })
            }}
            name="Search"
            isMulti={true}
            flush
          />
          <FloatingSelect
            label="Filter by Region"
            options={data.users
              .map(i =>
                i.regions && i.regions?.includes(",")
                  ? i.regions.split(",")
                  : i.regions
              )
              .filter(f => f !== "" && f !== null)
              .filter((v, i, a) => a.indexOf(v) === i)
              .map(i => {
                return {
                  value: i,
                  label: i,
                }
              })}
            value={filters.users.regions || null}
            onChange={e => {
              setFilters(prev => {
                return {
                  ...prev,
                  users: {
                    ...prev.users,
                    regions: e,
                  },
                }
              })
            }}
            isMulti={true}
            name="Search"
            flush
          />
          <FloatingSelect
            label="Filter by User Type"
            options={[
              { value: "lead", label: "Manager" },
              { value: "default", label: "Associate/Representative" },
            ]}
            value={filters.users.user_type || null}
            onChange={e => {
              setFilters(prev => {
                return {
                  ...prev,
                  users: {
                    ...prev.users,
                    user_type: e,
                  },
                }
              })
            }}
            name="Search"
            flush
          />
          {segment !== null && (
            <div className="grow w-full">
              <FloatingCheckbox
                label="Show only selected rows"
                name="selected"
                onChange={e => setShowSelectedOnly(e.target.checked)}
                value={showSelectedOnly}
                flush
                hookForm={false}
              />
            </div>
          )}
        </div>
      </div>

      {data.users.length === 0 ? (
        <Skeleton table />
      ) : (
        <Table fit columns={columns}>
          {data.users
            .filter(item => {
              if (showSelectedOnly) {
                if (segment !== null) {
                  return (
                    segments
                      .filter(
                        i => parseInt(i.id) === parseInt(segment.value)
                      )[0]
                      .field_group_users.filter(
                        i => parseInt(i.target_id) === parseInt(item.id)
                      ).length > 0
                  )
                } else if (create === true) {
                  return "field_group_users" in editData
                    ? editData?.field_group_users?.filter(
                      i => parseInt(i.target_id) === parseInt(item.id)
                    ).length > 0
                    : false
                }
              } else {
                return true
              }
            })
            .filter(item => {
              if (filters.users.search !== "") {
                return (
                  item.first_name
                    .toLowerCase()
                    .includes(filters.users.search.toLowerCase()) ||
                  item.last_name
                    .toLowerCase()
                    .includes(filters.users.search.toLowerCase())
                )
              } else {
                return true
              }
            })
            .filter(item => {
              if (
                filters.users.job_titles !== null &&
                filters.users.job_titles.length > 0
              ) {
                return (
                  filters.users.job_titles.filter(
                    i => i.value === item.job_title
                  ).length > 0
                )
              } else {
                return true
              }
            })
            .filter(item => {
              if (
                filters.users.regions !== null &&
                filters.users.regions.length > 0
              ) {
                return (
                  filters.users.regions.filter(i => i.value === item.regions)
                    .length > 0
                )
              } else {
                return true
              }
            })
            .filter(item => {
              if (filters.users.user_type !== null) {
                return filters.users.user_type.value === item.association
              } else {
                return true
              }
            })
            .map((item, k) => {
              let regions = item.regions
              if (item.regions !== null && item.regions.length)
                regions = item.regions
                  .split(",")
                  .map(i => CAProvinces.find(f => f.short === i)?.name || "")
                  .join(", ")

              return (
                <Tr key={k}>
                  {((segment !== null &&
                    editData !== null &&
                    Object.keys(editData).length > 0) ||
                    create === true) && (
                      <Td className="w-[40px] pr-0">
                        <FloatingCheckbox
                          name="selected"
                          value={
                            "field_group_users" in editData &&
                              editData.field_group_users.filter(
                                i => parseInt(i.target_id) === parseInt(item.id)
                              ).length > 0
                              ? true
                              : false
                          }
                          hookForm={false}
                          disabled={!editing}
                          flush
                          onChange={e => {
                            if (e.target.checked) {
                              const existingUsers =
                                "field_group_users" in editData
                                  ? editData.field_group_users
                                  : []
                              setEditData({
                                ...editData,
                                field_group_users: [
                                  ...existingUsers,
                                  {
                                    target_id: parseInt(item.id),
                                  },
                                ],
                              })
                            }
                            if (!e.target.checked) {
                              setEditData({
                                ...editData,
                                field_group_users:
                                  editData.field_group_users.filter(
                                    i =>
                                      parseInt(i.target_id) !== parseInt(item.id)
                                  ),
                              })
                            }
                          }}
                        />
                      </Td>
                    )}
                  <Td>{item.first_name}</Td>
                  <Td>{item.last_name}</Td>
                  <Td>{item.job_title}</Td>
                  <Td>{regions}</Td>
                  <Td>{item.association ? item.association === "lead" ? "Manager" : "Associate/Representative" : ""}</Td>
                </Tr>
              )
            })}
        </Table>
      )}
    </>
  )
}
export default Users
