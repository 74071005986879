/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, } from "react"
import LayoutDashborad from "../../components/Layout/Dashboard"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import AccessDenied from "../../components/AccessDenied"
import MyUserSegmentationProvider from "../../context/MyUserSegmentation/MyUserSegmentationProvider"
import PageSubtitle from "../../components/PageSubtitle"
function UserSegmentation({ location }) {
  const { userType } = useContext(AuthenticationContext)

  if (!userType.brandManager) return <AccessDenied />
  return (
    <LayoutDashborad
      heading={"User Segmentation"}
      // subheading={"View, edit or create User Segments that your company can apply to its assets, products, brands, inventory and more."}
    >
      <PageSubtitle title="View, edit or create User Segments that your company can apply to its assets, products, brands, inventory and more." />
      <MyUserSegmentationProvider>
      </MyUserSegmentationProvider>
    </LayoutDashborad>
  )
}

export default UserSegmentation
